import {createContext, useState} from "react";

export const CartContext = createContext(undefined);

export const CartProvider = ({ children }) => {
    const [products, setProducts] = useState({});
    const [donation, setDonation] = useState(undefined);
    const [originalDonation, setOriginalDonation] = useState(undefined);
    const [total, setTotal] = useState(0);
    const [subtotal, setSubtotal] = useState(0);
    const [transactionFee, setTransactionFee] = useState(undefined);
    const [donationSettings, updateDonationSettings] = useState(undefined);
    const [custref, setCustRef] = useState(undefined);

    const parseNumber = function(amount) {
        if (amount === "0") {
            return 0
        }
        return parseFloat(amount.toString().replaceAll(",", ""))
    }

    const recalculateAndSetTotal = function() {
        let dollars = calculateTotal()
        setTotal(dollars)

        dollars = calculateSubtotal()
        setSubtotal(dollars)

        return dollars
    }

    const calculateSubtotal = function() {
        let dollars = 0
        for (let key of Object.keys(products)) {
            dollars += parseNumber(products[key].price)
        }

        return dollars;
    }

    const roundUpToNearestCent = function(amount) {
        return Math.ceil(amount * 100) / 100
    }

    const calculateCoveredCost = function(rate) {
        let processingFee = roundUpToNearestCent((rate / 100) * (calculateTotalWithoutDonation() + originalDonation.price))

        processingFee += originalDonation.price

        return processingFee
    }

    const setDonationSettings = function(newDonation) {
        updateDonationSettings(newDonation)

        if (newDonation === undefined) {
            return
        }

        let rate = undefined
        if (newDonation.rate) {
            rate = parseNumber(newDonation.rate)
        }

        if (newDonation.isSelected && newDonation.price && rate && originalDonation && donation) {
            let coveredCost = calculateCoveredCost(rate)
            let newDonation = {
                id: donation.id,
                name: donation.name,
                price: coveredCost
            }

            setDonation(newDonation);
        } else {
            setDonation(originalDonation)
        }
    }

    const calculateTotalWithoutDonation = function() {
        let dollars = 0
        for (let key of Object.keys(products)) {
            dollars += parseNumber(products[key].price)

            if (transactionFee && transactionFee.type === "amount" && transactionFee.applied_per === "transaction") {
                dollars += parseNumber(transactionFee.amount * products[key].quantity)
            }
        }

        if (transactionFee && transactionFee.type === "percent" && transactionFee.applied_per === "form") {
            dollars += transactionFee.amount * dollars
        }

        return dollars
    }

    const calculateTotal = function() {
        var dollars = calculateTotalWithoutDonation()

        if (donation) {
            dollars += parseNumber(donation.price)
        }

        return dollars;
    }

    const removeProduct = function(id) {
        delete products[id]
        recalculateAndSetTotal()
    }

    const removeDonation = function() {
        setDonation(undefined)
        recalculateAndSetTotal()
    }

    const updateDonation = function(id, name, price) {
        let newDonation = {
            id: id,
            name: name,
            price: parseNumber(price)
        }
        setDonation(newDonation);
        setOriginalDonation(newDonation)

        recalculateAndSetTotal()
    }

    const updateProduct = (id, name, quantity, price, quantityAvailable, amountSold, productActivityInProgressText) => {
        setProducts((prevProducts) => ({
            ...prevProducts,
            [id.toString()]: {
                name,
                quantity,
                price: parseFloat(price) * quantity,
                quantityAvailable,
                amountSold,
                productActivityInProgressText,
            },
        }));

        recalculateAndSetTotal()
    }

    return (
        <CartContext.Provider
            value={{
                donation,
                updateDonation,
                updateProduct,
                removeDonation,
                removeProduct,
                products,
                transactionFee,
                setTransactionFee,
                total,
                calculateTotal,
                subtotal,
                calculateSubtotal,
                recalculateAndSetTotal,
                custref,
                setCustRef,
                setDonationSettings,
                donationSettings,
                calculateCoveredCost
            }}
        >
            {children}
        </CartContext.Provider>
    );
};
